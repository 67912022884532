const cursor = document.getElementById('cursor');

document.addEventListener('mousemove', ({ clientX: x, clientY: y }) => {
  if (window.innerWidth > 1024) {
    cursor.style.left = `${x}px`;
    cursor.style.top = `${y}px`;
    cursor.style.display = 'block'; // Ensure the cursor is visible when moving
  }
});

document.addEventListener('mouseleave', () => {
  cursor.style.display = 'none'; // Hide the cursor when leaving the window
});

document.addEventListener('mouseenter', () => {
  cursor.style.display = 'block'; // Show the cursor when re-entering the window
});

const interactiveElements = document.querySelectorAll('a, button');

interactiveElements.forEach(element => {
  element.addEventListener('mousemove', () => {
    if (window.innerWidth > 1024) {
      cursor.classList.add('hover-cursor');
    }
  });

  element.addEventListener('mouseleave', () => {
    if (window.innerWidth > 1024) {
      cursor.classList.remove('hover-cursor');
    }
  });
});
